<template>
  <div>
    <h1 class="c-orange">LISTADO DE FAVORITOS</h1>
    <v-spacer />
    <TableFuncionarios
      @send-data="sendInfo"
      :searchMode="false"
      :adminMode="false"
      :favoritosMode="true"
      :headers="headers"
      :funcionarios="funcionarios"
      :cargando="cargando"
      :procesoActivo="procesoActivo"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/TableCrud.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios,
  },
  data() {
    return {
      cargando: true,
      loading: "cargando",
      itemType: "Proceso",
      headers: [
        { text: "Nombre", value: "identificacion.nombre" },
        { text: "Puesto", value: "identificacion.puestoTrabajo.nombre" },
        { text: "Unidad", value: "identificacion.unidad" },
        { text: "Planta", value: "identificacion.planta" },
        { text: "Grado", value: "identificacion.grado" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      funcionarios: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      procesoActivo: false,
    };
  },
  created() {
    this.getAllFuncionarios();
  },
  methods: {
    sendInfo(event) {
      this.getAllFuncionarios();
    },
    getAllFuncionarios() {
      this.cargando = true;
      this.funcionarios = [];
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/favorito/obtenerFavoritosPorProceso?idProceso=" +
            this.$route.params.idProceso,
          this.auth
        )
        .then((response) => {
          this.funcionarios = response.data;
          if (response.data.length > 1) {
            console.log(response.data[0]?.proceso?.activo);
            this.procesoActivo = response.data[0]?.proceso?.activo;
          }
          this.cargando = false;
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },
  },
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>
