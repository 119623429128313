<template>
  <v-data-table
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="tableRows"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <div v-if="activeProcesses && admin">
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" class="mx-1" dark v-on="on"
                >Agregar {{ itemType }}</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Agregar {{ itemType }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="folio"
                      outlined
                      dense
                      hint="Ingrese el folio del proceso"
                      persistent-hint
                      label="Folio"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="cupos"
                      outlined
                      dense
                      hint="Ingrese la cantidad de cupos disponibles"
                      persistent-hint
                      label="Vacantes"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="puestoSelected"
                      :items="puestosTrabajo"
                      item-text="nombre"
                      return-object
                      outlined
                      dense
                      hint="Seleccione el puesto de trabajo"
                      persistent-hint
                      @change="calcularRango"
                      label="Puesto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="puestoSelected.planta"
                      disabled
                      outlined
                      dense
                      hint="Planta a la que pertenece el puesto de trabajo"
                      persistent-hint
                      label="Planta"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="grado"
                      :items="rangoPuesto"
                      outlined
                      dense
                      hint="Selecciona el grado buscado en el proceso"
                      persistent-hint
                      label="Grado"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formatDateInicio"
                          label="Fecha de inicio"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          hint="Selecciona la fecha de inicio"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-ES"
                        :first-day-of-week="1"
                        v-model="fechaInicio"
                        no-title
                        scrollable
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formatDateTermino"
                          label="Fecha de Termino"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          hint="Selecciona la fecha de término"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-ES"
                        :first-day-of-week="1"
                        v-model="fechaTermino"
                        no-title
                        scrollable
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="crearProceso()"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-col class="p-0" offset-md="4" offset-sm="2" offset-xs="0">
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>

    <template v-slot:item.acciones="{ item }">
      <v-btn
        icon v-if="admin"
        :to="{ name: 'ListadoFavoritos',  params: { idProceso: item.id } }"
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>
       <v-btn
        icon v-if="!admin && activeProcesses"
        :to="{ name: 'BuscadorFuncionarios',  params: { idProceso: item.id } }"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn
        icon v-if="!admin"
        :to="{ name: 'ListadoFavoritosJefatura',  params: { idProceso: item.id } }"
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>
      
      <v-btn v-if="admin" color="primary" dark @click="dialog2 = true"
        >Cambiar Estado</v-btn
      >
      <!-- <v-icon medium @click="favoriteItem(item)">mdi-star</v-icon> -->
      <v-dialog v-model="dialog2" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Cambio de estado</span>
        </v-card-title>
        <v-card-text>¿Está seguro que desea cambiar el estado del proceso con Folio {{item.folio}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog2=false">No</v-btn>
          <v-btn color="blue darken-1" text @click="cambiarEstado(item.id)">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-btn v-if="activeProcesses" icon @click="editarItem(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-dialog v-model="dialog3" max-width="500px">
        <template v-slot:activator="{ on }">
          <!--   <v-btn color="primary" class="mx-1" dark v-on="on"
              >Agregar {{ itemType }}</v-btn
            > -->
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Editar Proceso</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.folio"
                  outlined
                  dense
                  hint="Ingrese el folio del proceso"
                  persistent-hint
                  label="Folio"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.cupos"
                  outlined
                  dense
                  hint="Ingrese la cantidad de cupos disponibles"
                  persistent-hint
                  label="Vacantes"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.puestoTrabajo"
                  disabled
                  :items="puestosTrabajo"
                  item-text="nombre"
                  return-object
                  outlined
                  dense
                  hint="Seleccione el puesto de trabajo"
                  persistent-hint
                  @change="calcularRangoProcesoActualizado()"
                  label="Puesto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.puestoTrabajo.planta"
                  disabled
                  outlined
                  dense
                  hint="Planta a la que pertenece el puesto de trabajo"
                  persistent-hint
                  label="Planta"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.grado"
                  :items="rangoPuesto"
                  outlined
                  dense
                  hint="Selecciona el grado buscado en el proceso"
                  persistent-hint
                  label="Grado"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatDateInicio"
                      label="Fecha de inicio"
                      prepend-inner-icon="mdi-calendar"
                      persistent-hint
                      hint="Selecciona la fecha de inicio"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-ES"
                    :first-day-of-week="1"
                    v-model="fechaInicio"
                    no-title
                    scrollable
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatDateTermino"
                      label="Fecha de Termino"
                      prepend-inner-icon="mdi-calendar"
                      persistent-hint
                      hint="Selecciona la fecha de termino"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-ES"
                    :first-day-of-week="1"
                    v-model="fechaTermino"
                    no-title
                    scrollable
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarEditarItem()"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="actualizarProceso()"
              >Actualizar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:no-data>No hay procesos para la búsqueda</template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    admin: Boolean,
    activeProcesses: Boolean,
    itemType: String,
    ccSelected: String,
    headers: Array,
    tableRows: Array,
  },
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      dialog: false,
      search: "",
      height: 300,
      fixed: true,
      menu1: false,
      menu2: false,
      fechaInicio: "",
      fechaTermino: "",
      folio: "",
      cupos: "",
      grado: "",
      puestoSelected: {},
      rangoPuesto: [],
      puestosTrabajo: [],
      dialog2: false,
      dialog3: false,
      editedItem: {
        puestoTrabajo: {
          planta: "",
        },
      },
    };
  },
  computed: {
    formatDateInicio() {
      return this.formatDate(this.fechaInicio);
    },
    formatDateTermino() {
      return this.formatDate(this.fechaTermino);
    },
  },
  watch: {
    editedItem: function () {
      this.calcularRangoProcesoActualizado();
    },
  },
  created() {
    if (this.activeProcesses) this.getPuestos();
  },
  methods: {
    editarItem(item) {
      this.editedItem = item;
      this.dialog3 = true;
    },
    cerrarEditarItem() {
      this.editedItem = {
        puestoTrabajo: {
          planta: "",
        },
      };
      this.dialog3 = false;
    },
    sendData() {
      this.$emit("send-data", "OK");
    },
    getPuestos() {
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/puestoTrabajo/getAll", this.auth)
        .then((response) => {
          this.puestosTrabajo = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    actualizarProceso() {
      this.editedItem.fechaInicio = this.formatDateInicio;
      this.editedItem.fechaTermino = this.formatDateTermino;
      this.axios
        .put(
          process.env.VUE_APP_BASE_URL + "/proceso/actualizarProceso",
          this.editedItem,
          this.auth
        )
        .then((response) => {
          this.cerrarEditarItem();
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    crearProceso() {
      const proceso = {
        activo: true,
        cupos: this.cupos,
        fechaInicio: this.formatDateInicio,
        fechaTermino: this.formatDateTermino,
        folio: this.folio,
        grado: this.grado,
      };
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/proceso/createProceso?ccValor=" +
            this.ccSelected +
            "&idPuestoTrabajo=" +
            this.puestoSelected.id,
          proceso,
          this.auth
        )
        .then((response) => {
          this.dialog = false;
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    seeItem(item) {
      confirm("ver item de " + item.puesto);
    },
    favoriteItem(item) {
      confirm("ver favoritos de " + item.puesto);
    },

    calcularRango() {
      this.rangoPuesto = [];
      for (
        var i = this.puestoSelected.rangoMax;
        i <= this.puestoSelected.rangoMin;
        i++
      ) {
        this.rangoPuesto.push(i);
      }
    },
    calcularRangoProcesoActualizado() {
      this.rangoPuesto = [];
      for (
        var i = this.editedItem.puestoTrabajo.rangoMax;
        i <= this.editedItem.puestoTrabajo.rangoMin;
        i++
      ) {
        this.rangoPuesto.push(i.toString());
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cambiarEstado(id) {
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL +
            "/proceso/cambiarEstado?estado=" +
            !this.activeProcesses +
            "&id=" +
            id,
          this.auth
        )
        .then((response) => {
          this.sendData();
          this.dialog2 = false;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 12px 24px 0 !important;
}
.p-0 {
  padding: 0;
}
</style>