<template>
  <v-content>
    <v-row>
      <v-tabs fixed-tabs background-color="grey" dark>
        <v-tab :to="{name: 'curriculumAdmin'}">Identificación</v-tab>
        <v-tab :to="{name: 'formacionAdmin'}">Formación</v-tab>
        <v-tab :to="{name: 'experienciaAdmin'}">Experiencia</v-tab>
      </v-tabs>
    </v-row>
  </v-content>
</template>

<script>
export default {
  name: "TabAdmin"
};
</script>


<style scoped>
.nav {
  margin-bottom: 1% !important;
}
.test {
  height: 300px !important;
  overflow-y: auto;
}
</style>