<template>
  <v-content>
    <v-row>
      <v-col cols="12">
        <TabPerfil />
      </v-col>
    </v-row>

    <v-row class="test">
      <router-view></router-view>
    </v-row>
  </v-content>
</template>

<script>
import TabPerfil from "./TabPerfil";
export default {
  name: "LayoutPerfil",
  components: {
    TabPerfil
  }
};
</script>


<style scoped>
.nav {
  margin-bottom: 1% !important;
}

.test {
  max-height: 70vh !important;
  overflow-y: auto;
}
</style>