<template>
  <v-container>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <h2 class="c-orange">
      Cargar información
    </h2>
    <v-row>
      <v-col>
        <v-file-input
          v-model="familia"
          placeholder="Seleccione familia"
          label="Familia"
          outlined
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col
        ><v-btn depressed color="warning" width="400" @click="sendFamilia" :disabled="familia === null">
          Cargar Familia
        </v-btn></v-col
      >
    </v-row>

    <v-row>
      <v-col>
        <v-file-input v-model="puestos" label="Puestos" outlined dense></v-file-input>

      </v-col>
      <v-col
        ><v-btn depressed color="warning" width="400" @click="sendPuestos" :disabled="puestos === null">
          Cargar Puestos
        </v-btn></v-col
      >
    </v-row>

    <v-row>
      <v-col>
        <v-file-input
          v-model="posicion"
          placeholder="Seleccione el archivo de posiciones"
          label="Posiciones"
          outlined
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col
        ><v-btn depressed color="warning" width="400" @click="sendPosiciones" :disabled="posicion === null">
          Cargar Posiciones
        </v-btn></v-col
      >
    </v-row>

    <v-row>
      <v-col>
        <v-file-input
          v-model="dotacion"
          placeholder="Puede seleccionar mas de un archivo"
          label="Dotación"
          outlined
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col
        ><v-btn depressed color="warning" width="400" @click="sendDotacion" :disabled="dotacion === null">
          Cargar Dotación
        </v-btn></v-col
      >
    </v-row>



    <!-- <v-row>
      <v-col>
        <v-file-input v-model="capacitaciones" label="Capacitaciones" outlined dense></v-file-input>
      </v-col>
      <v-col
        ><v-btn depressed color="warning" width="400" @click="sendCapacitacion">
          Cargar Capacitaciones
        </v-btn></v-col
      >
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      familia: null,
      dotacion: null,
      puestos: null,
      capacitaciones: null,
      posicion: null,
      overlay: false
    };
  },

  methods: {
    submitFamilia(formData) {
      for (var value of formData.values()) {
       console.log(value);
      }
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/familia/createAllFamilias?indiceHoja=4",
          formData,
          this.auth
        )
        .then((response) => {
          this.overlay = false;
          this.familia = null;
          alert('Familias creadas');
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          alert(error.response);
        });
    },

    sendFamilia() {
      this.overlay = true;
      let formData = new FormData();
      formData.append('file', this.familia);
      if (!(this.familia === null)) {
        this.submitFamilia(formData);
      }
      else {
        alert('No hay archivo para subir');
        this.overlay === false;
      }
    },

    submitDotacion(formData) {
      for (var value of formData.values()) {
       console.log(value);
      }
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/identificacion/createAllIdentificacions?indiceHoja=8",
          formData,
          this.auth
        )
        .then((response) => {
          this.overlay = false;
          this.dotacion = null;
          alert('Usuarios creados');
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.overlay = true;
          alert(error.response);
        });
    },

    sendDotacion() {
      this.overlay = true;
      let formData = new FormData();
      formData.append('file', this.dotacion);
      if (!(this.dotacion === null)) {
        this.submitDotacion(formData);
      }
      else {
        alert('No hay archivo para subir');
        this.overlay === false;
      }
    },

    submitPuestos(formData) {
      for (var value of formData.values()) {
       console.log(value);
      }
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/puestoTrabajo/createAllPuestoTrabajos?indiceHoja=5",
          formData,
          this.auth
        )
        .then((response) => {
          this.overlay = false;
          this.puestos = null;
          alert('Puestos de trabajo creados');
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.overlay = false;
          alert(error.response);
        });
    },

    sendPuestos() {
      this.overlay = true;
      let formData = new FormData();
      formData.append('file', this.puestos);
      if (!(this.puestos === null)) {
        this.submitPuestos(formData);
      }
      else {
        alert('No hay archivo para subir');
        this.overlay === false;
      }
    },

    submitPosiciones(formData) {
      for (var value of formData.values()) {
        console.log(value);
      }
      this.axios
      .post(
        process.env.VUE_APP_BASE_URL +
        "/posicionTrabajo/createAllPosicionTrabajos?indiceHoja=7",
        formData,
        this.auth
      )
      .then((response) => {
        this.posicion = null;
        this.overlay = false;
        alert('Posiciones creados');
      })
      .catch((error) => {
        this.overlay = false;
        console.log(error);
        console.log(error.response);
        alert(error.response);
      });
    },

    sendPosiciones() {
      this.overlay = true;
      let formData = new FormData();
      formData.append('file', this.posicion);
      if (!(this.posicion === null)) {
        this.submitPosiciones(formData);
      }
      else {
        alert('No hay archivo para subir');
        this.overlay === false;
      }
    },

    submitCapacitacion(formData) {
      for (var value of formData.values()) {
       console.log(value);
      }
      // this.axios
      //   .post(
      //     process.env.VUE_APP_BASE_URL +
      //       "/files/uploadFiles?docType=Instructivo",
      //     formData,
      //     this.auth
      //   )
      //   .then((response) => {
      //     alert(response.data.message);
      //     this.sendData();
      //     this.close();
      //   })
      //   .catch((error) => {
      //     alert(error.response);
      //   });
    },

    sendCapacitacion() {
      let formData = new FormData();
      formData.append('file', this.capacitaciones);
      this.submitCapacitacion(formData);
    },
  }
};
</script>
