<template>
  <v-data-table
    :loading="cargando"
    :show-select="!searchMode"
    v-model="selected"
    item-key="id"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="funcionarios"
    :search="search"
    :procesoActivo="procesoActivo"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title v-if="favoritosMode">Favoritos</v-toolbar-title>
        <v-toolbar-title v-if="!favoritosMode"
          >Listado Funcionarios</v-toolbar-title
        >

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="mailDialog" v-if="procesoActivoWatch" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              class="mx-1"
              dark
              v-on="on"
              :disabled="buttonDisabled"
              >Enviar Correo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Enviar correo</span>
            </v-card-title>
            <v-card-text>
              ¿Quiere enviar un correo de contacto a los siguientes
              funcionarios?
              <ul>
                <li v-for="(funcionario, index) in selected" :key="index">
                  {{ funcionario.identificacion.nombre }}
                </li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="mailDialog = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="sendMail()"
                >Enviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteSelected" v-if="procesoActivoWatch" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="error"
              class="mx-1"
              dark
              v-on="on"
              >Eliminar Seleccionados</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Eliminar</span>
            </v-card-title>
            <v-card-text>
              ¿Seguro que quiere eliminar los siguientes usuarios del listado?
              <ul>
                <li v-for="(funcionario, index) in selected" :key="index">
                  {{ funcionario.identificacion.nombre }}
                </li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteSelected = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="borrarSeleccionados()"
                >Eliminar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-text-field
          outlined
          class="mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-btn icon :to="{ name: 'funcionario', params: { id: item.id } }">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-icon
        v-if="!favoritosMode && !adminMode"
        @click="convertirFavorito(item.id)"
        >mdi-star</v-icon
      >
      <v-icon
        v-if="favoritosMode && !adminMode && procesoActivo"
        @click="cambiarRanking(item.id, 1)"
        >mdi-arrow-up-thick</v-icon
      >
      <v-icon
        v-if="favoritosMode && !adminMode && procesoActivo"
        @click="cambiarRanking(item.id, -1)"
        >mdi-arrow-down-thick</v-icon
      >
    </template>

    <template v-slot:no-data> </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    searchMode: Boolean,
    headers: Array,
    funcionarios: Array,
    adminMode: Boolean,
    favoritosMode: Boolean,
    ccSelected: Array,
    cargando: Boolean,
    procesoActivo: Boolean,
  },
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      funcionariosLocal: [],
      dialog: false,
      mailDialog: false,
      deleteSelected: false,
      search: "",
      height: 400,
      fixed: true,
      selected: [],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        puesto: "",
        unidad: "",
        planta: "",
        grado: "",
      },
      defaultItem: {
        nombre: "",
        puesto: "",
        unidad: "",
        planta: "",
        grado: "",
      },
      showButtons: false
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Funcionario"
        : "Editar Funcionario";
    },
    procesoActivoWatch(){
      return this.favoritosMode && !this.adminMode && this.procesoActivo;
    },
    buttonDisabled(){
      return this.selected.length === 0;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    selected() {
      console.log(this.selected);
    },
  },

  created() {
    //Object.assign(this.funcionariosLocal, this.funcionarios);
    this.funcionariosLocal = JSON.parse(JSON.stringify(this.funcionarios));
  },

  mounted() {
    this.showButtons = this.favoritosMode && !this.adminMode && this.procesoActivo;
  },

  methods: {
    sendData() {
      this.$emit("send-data", "OK");
    },
    bulkDelete() {
      this.selected.forEach((value) => {
        var i = this.funcionariosLocal.indexOf(value);
        this.funcionariosLocal.splice(i, 1);
      });
      this.selected = [];
      this.deleteSelected = false;
      this.sendData();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.funcionariosLocal[this.editedIndex],
          this.editedItem
        );
      } else {
        this.funcionariosLocal.push(this.editedItem);
      }
      this.sendData();
      this.close();
    },
    convertirFavorito(idFuncionario) {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/favorito/createFavorito?idIdentificacion=" +
            idFuncionario +
            "&idProceso=" +
            this.$route.params.idProceso,
          this.auth
        )
        .then((response) => {
          if (response.data == true) {
            alert("El/La funcionario/a ha sido añadida al listado de favoritos, será quitado/a de la lista de funcionarios");
          } else {
            alert("Persona no añadida al listado de favoritos");
          }
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },
    cambiarRanking(idFavorito, variacion) {
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL +
            "/favorito/cambiarRanking?id=" +
            idFavorito +
            "&variacionRanking=" +
            variacion,
          this.auth
        )
        .then((response) => {
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },
    borrarSeleccionados() {
      console.log(this.selected);
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL + "/favorito/borrarFavoritos",
          { data: this.selected },
          this.auth
        )
        .then((response) => {
          alert(response.data);
          this.sendData();
          this.selected = [];
          this.deleteSelected = false;
        })
        .catch((error) => {
          alert(error);
          this.selected = [];
          this.deleteSelected = false;
        });
    },
    sendMail() {
      const userMails = this.selected.map(user => ({identificacion: {
        correoLdap: user.identificacion?.correoLdap
      }}));
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/favorito/enviarCorreo",
          userMails,
          this.auth
        )
        .then((response) => {
          alert(response.data);
          this.sendData();
          this.selected = [];
          this.mailDialog = false;
        })
        .catch((error) => {
          alert(error);
          this.selected = [];
          this.deleteSelected = false;
        });
    }
  },
};
</script>
