import axios from 'axios';

const state = {
  authenticated: false,
  token: '',
  user: {}
}

const getters = {
  token: state => state.token,
  authenticated: state => state.authenticated,
  user: state => state.user
}

const actions = {
  setToken: async (context, token) => {
    context.commit('SET_ACCESS_TOKEN', token);
    // llama a la API
    const {data} = await axios.get(`https://jsonplaceholder.typicode.com/todos/1`);
    context.commit('SET_USER', data);
    // return async () => {
    //   console.log(token);
    //   // commit('SET_AUTH_STATUS', true);
    // }
  },
  // setUser: ({commit}, user) => {
  //
  // }
}

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER: (state, data) => {
    state.user = data;
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
