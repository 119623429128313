<template>
  <v-content>
    <v-row>
      <v-tabs fixed-tabs background-color="grey" dark>
        <v-tab :to="{name: 'curriculum'}">Identificación</v-tab>
        <v-tab :to="{name: 'formacion'}">Formación</v-tab>
        <v-tab :to="{name: 'experiencia'}">Experiencia</v-tab>
        <v-tab :to="{name: 'capacitacion'}">Capacitaciones</v-tab>
        <!-- <v-tab :to="{name: 'interes'}">Puesto de Interés</v-tab> -->
      </v-tabs>
    </v-row>
  </v-content>
</template>

<script>
export default {
  name: "Tab"
};
</script>


<style scoped>
.nav {
  margin-bottom: 1% !important;
}
.test {
  height: 300px !important;
  overflow-y: auto;
}
</style>