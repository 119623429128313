<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="11">
        <p>
          La siguiente lista muestra los puestos a los cuales puede postular,
          teniendo en consideración el cumplimiento del Decreto Universitario
          1444/96 y la información expuesta en su perfil personal. Para
          facilitar la búsqueda de nuestras(os) reclutadoras(es) tiene la opción
          de seleccionar aquellos que se ajustan a sus intereses. Su elección no
          afectará las posibilidades de ser preseleccionado(a), solo se
          considerará como información complementaria dentro del proceso de
          reclutamiento.
        </p>
      </v-col>

      <v-col cols="6">
        <v-data-table
          :headers="headers"
          :height="300"
          :items="desserts"
          :dense="true"
          class="elevation-1"
        >
          <template v-slot:header.name="{ header }">
            {{ header.text.toUpperCase() }}
          </template>
          <template v-slot:item.accion="{ item }">
            <v-btn color="green" icon @click="addPuestosInteres(item.id)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <v-chip
          v-for="(puestoInteres, index) in puestosDeInteres"
          :key="index"
          class="ma-2"
          close
          color="green"
          label
          outlined
          @click:close="deletePuestoInteres(puestoInteres.id)"
        >
          {{ puestoInteres.nombrePuestoTrabajo }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
import ModalConfirm from "./ModalConfirmacion";

export default {
  components: {
    Modal,
    ModalConfirm,
  },
  data() {
    return {
      puestosDeInteres: [
        { name: "Puesto de interes seleccionado 1" },
        { name: "Puesto de interes seleccionado 2" },
        { name: "Puesto de interes seleccionado 3" },
      ],
      dense: false,
      fixedHeader: false,
      headers: [
        {
          text: "Puesto",
          align: "start",
          value: "nombre",
          width: "80%",
        },
        { text: "Acción", value: "accion" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
    };
  },

  created() {
    this.getPosiblesPuestos();
    this.getPuestosInteres();
  },
  methods: {
    getPosiblesPuestos() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/identificacion/getPosiblesPuestos?id=" +
            this.$route.params.id,
          this.auth
        )
        .then((response) => {
          this.desserts = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getPuestoTrabajo(identificacion) {
      console.log(identificacion);

      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/puestoTrabajo/getAllByPlantaAndRango?planta=" +
            identificacion.planta +
            "&rango=" +
            identificacion.grado,
          this.auth
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          alert(error);
        });
    },

    getPuestosInteres() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/puestoInteres/obtenerListadoPorIdentificacion?idIdentificacion=" +
            this.$route.params.id,

          this.auth
        )
        .then((response) => {
          this.puestosDeInteres = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          alert(error);
        });
    },

    addPuestosInteres(id) {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/puestoInteres/createPuestoInteres?idIdentificacion=" +
            this.$route.params.id +
            "&idPuesto=" +
            id,
          this.auth
        )
        .then(() => {
          this.getPuestosInteres();
          this.getPosiblesPuestos();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deletePuestoInteres(id) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL +
            "/puestoInteres/eliminarPuestoInteres?id=" +
            id,
          this.auth
        )
        .then((response) => {
          alert("Se ha eliminado un puesto de interes");
          this.getPuestosInteres();
          this.getPosiblesPuestos();
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}
.textColor {
  background: orange;
  color: white !important;
}
</style>