<template>
  <div>
    <h2 class="c-orange">Instructivos y Procedimientos</h2>
    <v-card>
      <TableFile
        @send-data="sendInfo"
        v-bind:itemType="title"
        v-bind:headers="headers"
        v-bind:tableRows="tableRows"
        v-bind:admin="false"
      />
    </v-card>
  </div>
</template>


<script>
import TableFile from "@/components/TableFile.vue";

export default {
  components: {
    TableFile,
  },
  data() {
    return {
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Tamaño", value: "size" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      tableRows: [],
      title: "Instructivos",
    };
  },

  created() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/files/getAll", this.auth)
        .then((response) => {
          this.tableRows = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    sendInfo() {
      this.getAllInstructivos();
    },
  },
};
</script>