<template>
    <v-footer fixed color="grey">
      <v-col class="text-center" cols="12">
        <strong>PRORRECTORÍA - UNIVERSIDAD DE SANTIAGO DE CHILE</strong>
      </v-col>
    </v-footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>