<template>
  <v-container>
    <h1 class="c-orange">INSTRUCTIVOS Y PROCEDIMIENTOS</h1>
    <v-row>
      <v-col cols="12">
        <v-textarea
          hint="Ingresa una descripción para los documentos agregados"
          persistent-hint
          :disabled="!edit"
          outlined
          counter
          no-resize
          label="Mensaje"
          v-model="docText"
        ></v-textarea>
        <v-row justify="end">
          <v-btn color="blue" class="mr-3" dark @click="changeButton(edit)">{{
            textButton
          }}</v-btn>
        </v-row>
      </v-col>
    </v-row>

    <TableFile
      @send-data="sendInfo"
      v-bind:itemType="title"
      v-bind:headers="headers"
      v-bind:tableRows="tableRows"
      v-bind:admin="true"
    />
  </v-container>
</template>

<script>
// @ is an alias to /src
import TableFile from "@/components/TableFile.vue";
export default {
  name: "UploadInstructive",
  components: {
    TableFile,
  },
  data() {
    return {
      docText: "texto descriptivo" || this.doctext,
      textButton: "Editar",
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Tamaño", value: "size" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      tableRows: [],
      title: "Instructivos",
      edit: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },
  created() {
    this.getAllInstructivos();
  },
  methods: {
    changeButton(edit) {
      this.edit = !edit;
      this.textButton = this.edit ? "Guardar" : "Editar";
    },
    sendInfo() {
      this.getAllInstructivos();
    },

    getAllInstructivos() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/files/getAllByType?docType=Instructivo",
          this.auth
        )
        .then((response) => {
          this.tableRows = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>