<template>
  <v-container>
    <v-row justify="center">
      <Modal
        @update-data="createFormacion"
        :title="'Crear Nueva formación'"
        :object="formacionVacia"
        :namesInputs="namesInputs"
        :nameButton="'Agregar formación'"
        :icon="'mdi-plus'"
        :color="'green'"
      />
      <v-col cols="10">
        <v-simple-table
          class="mt-5"
          v-for="(formacion, index) in formaciones"
          :key="index"
          :dense="true"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left border textColor">
                  {{ formacion.institucion }}
                </th>
                <th class="text-right no-border">
                  <v-chip
                    class="ma-2"
                    color="green"
                    v-if="formacion.verificado"
                    label
                    text-color="white"
                  >
                    <v-icon left> mdi-label </v-icon>
                    Verificado
                  </v-chip>
                  <ModalConfirm
                    v-if="!formacion.verificado"
                    @update-data="updateVerificacion"
                    :title="'¿Verificación de Formación?'"
                    :content="'Si estás conforme con la información subida por , presiona verificar'"
                    :nameButton="'verificar'"
                    :icon="'mdi-check'"
                    :color="'green'"
                    :id="formacion.id"
                  />

                  <Modal
                    @update-data="updateFormacion"
                    :title="'Editar datos de Formación'"
                    :object="formacion"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                  <ModalConfirm
                    @update-data="deleteFormacion"
                    :title="'Eliminar Formación'"
                    :content="'¿Estás seguro de eliminar esta formación?'"
                    :nameButton="'eliminar'"
                    :icon="'mdi-delete'"
                    :color="'red'"
                    :id="formacion.id"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Nivel Educacional</td>
                <td class="border-right">{{ formacion.nivelEducacional }}</td>
              </tr>
              <tr>
                <td class="border">Año de Egreso</td>
                <td class="border-right">{{ formacion.anoIngreso }}</td>
              </tr>
              <tr>
                <td class="border">Institución</td>
                <td class="border-right">{{ formacion.institucion }}</td>
              </tr>
              <tr>
                <td class="border">Especialidad</td>
                <td class="border-right">{{ formacion.especialidad }}</td>
              </tr>
              <tr>
                <td class="border">Formacion Complementario</td>
                <td class="border-right border-bot">
                  {{ formacion.formacionComplementaria }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
import ModalConfirm from "./ModalConfirmacion";
export default {
  components: {
    Modal,
    ModalConfirm,
  },
  data() {
    return {
      dense: false,
      fixedHeader: false,
      height: "auto",
      //agregar los objetos vacios
      formacionVacia: {
        nivelEducacional: "",
        anoIngreso: "",
        institucion: "",
        especialidad: "",
        formacionComplementaria: "",
        verificado: false,
      },
      formaciones: [],

      namesInputs: [
        { title: "Nivel Educacional", key: "nivelEducacional" },
        { title: "Año de Egreso", key: "anoIngreso" },
        { title: "Institución", key: "institucion" },
        { title: "Especialidad", key: "especialidad" },
        { title: "Formación complementaria", key: "formacionComplementaria" },
      ],
    };
  },

  created() {
    this.getFormaciones();
  },
  methods: {
    getFormaciones() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/formacion/obtenerListadoPorIdentificacion?idIdentificacion=" +
            this.$route.params.id,
          this.auth
        )
        .then((response) => {
          this.formaciones = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    updateFormacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL + "/formacion/update",
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha actualizado una formación");
          this.getFormaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },

    updateVerificacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL +
            "/formacion/cambiaEstadoVerificacion?id=" +
            event +
            "&verificado=true",
          this.auth
        )
        .then((response) => {
          alert("Se ha verificado una formación");
          this.getFormaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteFormacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL +
            "/formacion/eliminarFormacion?id=" +
            event,
          this.auth
        )
        .then((response) => {
          alert("Se ha eliminado una formación");
          this.getFormaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },
    createFormacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/formacion/createFormacion?idIdentificacion=" +
            this.$route.params.id,
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha añadido una formación");
          this.getFormaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}

.textColor {
  background: orange;
  color: white !important;
}
</style>