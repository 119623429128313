<template>
  <v-container>
    <v-row justify="center">
      <!-- <Modal
        @update-data="createCv"
        :title="'Crear Nueva formación'"
        :object="formacionVacia"
        :namesInputs="namesInputs"
        :nameButton="'Agregar formación'"
        :icon="'mdi-plus'"
        :color="'green'"
      /> -->
      <v-col cols="10">
        <v-simple-table
          class="mt-5"
          v-for="(formacion,index) in formaciones"
          :key="index"
          :dense="true"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left border textColor">{{formacion.name}}</th>
                <th class="text-right no-border">
                  <Modal
                    @update-data="updateCv"
                    :title="'Editar datos de Formación'"
                    :object="formacion"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Nivel Educacional</td>
                <td class="border-right">{{ formacion.nivelEducacional }}</td>
              </tr>
              <tr>
                <td class="border">Año de Egreso</td>
                <td class="border-right">{{ formacion.anoEgreso }}</td>
              </tr>
              <tr>
                <td class="border">Institución</td>
                <td class="border-right">{{ formacion.institucion }}</td>
              </tr>
              <tr>
                <td class="border">Especialidad</td>
                <td class="border-right">{{ formacion.especialidad }}</td>
              </tr>
              <tr>
                <td class="border">Formacion Complementario</td>
                <td class="border-right border-bot">{{ formacion.complementaria }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      dense: false,
      fixedHeader: false,
      height: "auto",
      formacionVacia: {
        id: 20,
        name: "",
        nivelEducacional: "",
        anoEgreso: "",
        institucion: "",
        especialidad: "",
        complementaria: ""
      },
      formaciones: [
        {
          id: 1,
          name: "Formación 1",
          nivelEducacional: "Completado",
          anoEgreso: "2019",
          institucion: "Universidad de Chile",
          especialidad: "Ejemplo",
          complementaria: "Ejemplo"
        },
        
      ],
      namesInputs: [
        "id",
        "Nombre",
        "Nivel Educacional",
        "Año de Egreso",
        "Institución",
        "Especialidad",
        "Formación complementaria"
      ]
    };
  },

  methods: {
    updateCv(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      alert("Ha modificado la formación  " + event.name);
    },
    createCv(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      alert("Ha creado la formación  " + event.name);
      this.formaciones.push(event);
    }
  }
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}

.textColor {
  background: orange;
  color: white !important;
}
</style>