<template>
  <v-container>
    <v-row justify="center">
      <Modal
        @update-data="createExp"
        :title="'Crear Nueva experiencia'"
        :object="experienciaVacia"
        :namesInputs="namesInputs"
        :nameButton="'Agregar experiencia'"
        :icon="'mdi-plus'"
        :color="'green'"
      />
      <v-col cols="10">
        <v-simple-table
          class="mt-5"
          v-for="(experiencia, index) in experiencias"
          :key="index"
          :dense="true"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left border textColor">
                  {{ experiencia.empresa }}
                </th>
                <th class="text-right no-border">
                  <v-chip
                    class="ma-2"
                    color="green"
                    v-if="experiencia.verificado"
                    label
                    text-color="white"
                  >
                    <v-icon left> mdi-label </v-icon>
                    Verificado
                  </v-chip>
                  <ModalConfirm
                    v-if="!experiencia.verificado"
                    @update-data="updateVerificacion"
                    :title="'¿Verificación de Experiencia?'"
                    :content="'Si estás conforme con la información subida por , presiona verificar'"
                    :nameButton="'verificar'"
                    :icon="'mdi-check'"
                    :color="'green'"
                    :id="experiencia.id"
                  />
                  <Modal
                    @update-data="updateExp"
                    :title="'Editar datos de Experiencia'"
                    :object="experiencia"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                  <ModalConfirm
                    @update-data="deleteFormacion"
                    :title="'Eliminar Experiencia'"
                    :content="'¿Estás seguro de eliminar esta experiencia?'"
                    :nameButton="'eliminar'"
                    :icon="'mdi-delete'"
                    :color="'red'"
                    :id="experiencia.id"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Cargo</td>
                <td class="border-right">{{ experiencia.cargo }}</td>
              </tr>
              <tr>
                <td class="border">Periodo</td>
                <td class="border-right">{{ experiencia.periodo }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de ingreso</td>
                <td class="border-right">{{ experiencia.fechaInicio }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de Término</td>
                <td class="border-right">{{ experiencia.fechaTermino }}</td>
              </tr>
              <tr>
                <td class="border">Empresa</td>
                <td class="border-right">{{ experiencia.empresa }}</td>
              </tr>
              <tr>
                <td class="border">Funciones</td>
                <td class="border-right">
                  {{ experiencia.descripcionLaboral }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
import ModalConfirm from "./ModalConfirmacion";

export default {
  components: {
    Modal,
    ModalConfirm,
  },
  data() {
    return {
      dense: false,
      fixedHeader: false,
      height: "auto",
      //agregar los objetos vacios
      experienciaVacia: {
        name: "",
        cargo: "",
        periodo: "",
        institucion: "",
        funciones: "",
      },
      experiencias: [],
      namesInputs: [
        { title: "Cargo", key: "cargo" },
        { title: "Empresa", key: "empresa" },
        { title: "Periodo", key: "periodo" },
        { title: "Fecha de inicio", key: "fechaInicio" },
        { title: "Fecha de termino", key: "fechaTermino" },
        { title: "Descripción laboral", key: "descripcionLaboral" },
      ],
    };
  },

  created() {
    this.getExperiencias();
  },
  methods: {
    getExperiencias() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/experiencia/obtenerListadoPorIdentificacion?idIdentificacion=" +
            this.$route.params.id,
          this.auth
        )
        .then((response) => {
          this.experiencias = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    updateExp(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL + "/experiencia/update",
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha actualizado una experiencia");
          this.getExperiencias();
        })
        .catch((error) => {
          alert(error);
        });
    },
    createExp(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/experiencia/createExperiencia?idIdentificacion=" +
            this.$route.params.id,
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha añadido una experiencia");
          this.getExperiencias();
        })
        .catch((error) => {
          alert(error);
        });
    },

    updateVerificacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL +
            "/experiencia/cambiaEstadoVerificacion?id=" +
            event +
            "&verificado=true",
          this.auth
        )
        .then((response) => {
          alert("Se ha verificado una experiencia");
          this.getExperiencias();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteFormacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL +
            "/formacion/eliminarExperiencia?id=" +
            event,
          this.auth
        )
        .then((response) => {
          alert("Se ha eliminado una experiencia");
          this.getExperiencias();
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}
.textColor {
  background: orange;
  color: white !important;
}
</style>