<template>
  <v-container>
    <h1></h1>
    <!-- <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-text-field
          hint="Ingresa el correo desde donde se enviará el mensaje"
          persistent-hint
          :disabled="deshabilitado"
          label="Desde"
          outlined
          dense
          v-model="desde"
        ></v-text-field>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-text-field
          hint="Ingresa los correos a los que se enviará el mensaje con copia oculta"
          persistent-hint
          :disabled="deshabilitado"
          label="CCO"
          outlined
          dense
          v-model="cco"
        ></v-text-field>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-text-field
          hint="Ingresa el asunto del mensaje a enviar"
          persistent-hint
          :disabled="deshabilitado"
          label="Asunto"
          outlined
          dense
          v-model="asunto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="8">
        <v-textarea
          hint="Ingresa el cuerpo del mensaje a enviar"
          persistent-hint
          :disabled="deshabilitado"
          outlined
          counter
          no-resize
          label="Mensaje"
          v-model="mensaje"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="8">
        <v-row justify="end">
          <v-btn color="warning" dark @click="actualizarMensaje()"
            >Editar</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      asunto: "",
      mensaje: "",
      correo: [],
    };
  },
  created() {
    this.getCorreo();
  },
  methods: {
    getCorreo() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/mensaje/obtenerMensaje?mensajeId=" +
            1,
          this.auth
        )
        .then((response) => {
          this.correo = response.data;
          this.asunto = this.correo.asunto;
          this.mensaje = this.correo.mensaje;
        })
        .catch((error) => {
          alert(error);
        });
    },
    actualizarMensaje() {
      const msj = {
        id: 1,
        asunto: this.asunto,
        mensaje: this.mensaje
      };
      this.axios
        .put(
          process.env.VUE_APP_BASE_URL + "/mensaje/actualizarMensaje",
          msj,
          this.auth
        )
        .then((response) => {
          console.log(response);
          alert("Se ha editado el mensaje genérico de correo electrónico")
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>