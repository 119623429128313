<template>
  <v-data-table
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="tableRows"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ itemType }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn v-if="admin" color="primary" dark class="mb-2" v-on="on"
              >Nuevos {{ itemType }}</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ itemType }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="files"
                      placeholder="Puede seleccionar mas de un archivo"
                      label="Subir Archivos"
                      outlined
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">{{ text }}</v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-icon v-if="admin" medium @click="deleteItemConfirmation(item)">mdi-delete</v-icon>
      <v-icon medium @click="downloadFile(item)">mdi-download</v-icon>
    </template>
    <template  v-slot:item.size="{ item }">
      {{ bytesToSize(item.size) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["itemType", "headers", "tableRows", "admin"],
  data() {
    return {
      dialog: false,
      search: "",
      height: 300,
      fixed: true,
      files: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},

  methods: {
    sendData() {
      this.$emit("send-data", "OK");
    },

    deleteItem(item) {
      this.axios
        .delete(process.env.VUE_APP_BASE_URL + "/files/" + item.id, this.auth)
        .then((response) => {
          alert(response.data.message);
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteItemConfirmation(item) {
      confirm(
        "¿Esta seguro de querer eliminar el archivo " + item.name + "?"
      ) && this.deleteItem(item);
    },
    downloadFile(item) {
      confirm(
        "¿Esta seguro que desea descargar el archivo " + item.name + "?"
      ) && window.open(item.url);
    },

    close() {
      this.dialog = false;
      this.files = [];
    },

    bytesToSize(bytes) {
      console.log(bytes);
      var sizes = ["B", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 B";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    submitFile(formData) {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/files/uploadFiles?docType=Instructivo",
          formData,
          this.auth
        )
        .then((response) => {
          alert(response.data.message);
          this.sendData();
          this.close();
        })
        .catch((error) => {
          alert(error.response);
        });
    },

    save() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file);
      });
      this.submitFile(formData);
    },
  },
};
</script>