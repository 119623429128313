<template>
  <v-app-bar fixed color="orange" class="nav mx-btn">
    <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      color="white"
      class="md-hidden"
    ></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" temporary app absolute>
      <v-list flat>
        <v-list-item-group v-model="group" color="deep-orange">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">Hola Usuario</v-list-item-title>
              <v-list-item-subtitle>perfil</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="{ name: 'menu' }">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'BuscarFuncionarioAdmin' }">
            <v-list-item-title>Funcionarios</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'ProcesosAdmin' }">
            <v-list-item-title>Procesos</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Configuración</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar-title class="text-white">MOVILIDAD INTERNA USACH</v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn depressed :to="{ name: 'menuFuncionario' }" v-if="$session.getAll().typeUser === ''" text color="white" class="sm-hidden"
      >Menú funcionario</v-btn
    >
    <v-btn depressed :to="{ name: 'menu' }" v-if="$session.getAll().typeUser === 'jefe'" text color="white" class="sm-hidden"
      >Menú Jefatura</v-btn
    >

    <v-btn depressed :to="{ name: 'menuAdmin' }" v-if="$session.getAll().typeUser === 'admin'" text color="white" class="sm-hidden"
      >Menú Administración</v-btn
    >
    <!-- <v-btn
      depressed
      :to="{ name: 'BuscarFuncionarioAdmin' }"
      text
      color="white"
      class="sm-hidden"
      >Funcionarios</v-btn
    >

    <v-btn
      depressed
      :to="{ name: 'ProcesosAdmin' }"
      text
      color="white"
      class="sm-hidden"
      >Procesos</v-btn
    > -->

    <v-menu bottom left :offset-y="offset">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="sm-hidden mr-1">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group
          color="primary"
        >
          <v-list>
          <v-list-item>
            <v-btn depressed @click="logout()" text color="black" light
              >Cerrar sesión</v-btn
            >
          </v-list-item>
        </v-list>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      items: [{ nombre: "iniciar Sesión", route: 'login' }, { nombre: "Configuración", route: '/' }],
      offset: true,
      drawer: false,
      group: null,
      sidebarItems: [
        {
          icon: "mdi-wifi",
          text: "Funcionarios",
        },
        {
          icon: "mdi-bluetooth",
          text: "Procesos",
        },
        {
          icon: "mdi-chart-donut",
          text: "Configuración",
        },
      ],
      user: {},
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    userType() {
      console.log('holi');
      this.userType = this.$session.get('typeUser');
    }
  },
  computed: {
    sessionStatus() {
      console.log('holaaaa');
      if (this.$session.exists()) {
        const token = this.$session.get('jwt');

        return true;
      }
      else {
        console.log('buuu');
        return false;
      }
    },
    sessionRole() {
      console.log('por favor funcionaaaa');
      if (this.$session.exists()) {
        return this.$session.get('typeUser');
      }
      else {
        console.log('buuu');
        return false;
      }
    },

  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push("/login");
      // this.$router.go();

    },
  }
};
</script>
<style>
.text-white {
  color: white;
}
@media only screen and (min-width: 600px) {
  .md-hidden {
    display: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .sm-hidden {
    display: none !important;
  }
}
</style>
