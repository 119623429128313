<template>
  <v-data-table
    :loading="cargando"
    v-model="selected"
    item-key="nombre"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="funcionarios"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Listado Funcionarios</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" class="mx-1" dark v-on="on"
              >Nuevo Funcionario</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.rut"
                      label="Rut"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.unidad"
                      label="Unidad"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.planta"
                      label="Planta"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.grado"
                      label="Grado"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formatDateInicio"
                          label="Fecha de nacimiento"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          hint="Selecciona la fecha de nacimiento"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-ES"
                        :first-day-of-week="1"
                        v-model="editedItem.fechaNacimiento"
                        no-title
                        scrollable
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formatDateInicio"
                          label="Antigüedad del puesto de trabajo"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          hint="Selecciona la fecha de antigüedad del puesto de trabajo"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-ES"
                        :first-day-of-week="1"
                        v-model="editedItem.antiguedadPuesto"
                        no-title
                        scrollable
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="formatDateInicio"
                          label="Antigüedad en la universidad"
                          prepend-inner-icon="mdi-calendar"
                          persistent-hint
                          hint="Selecciona la fecha de antigüedad en la universidad"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-ES"
                        :first-day-of-week="1"
                        v-model="editedItem.antiguedadUniversidad"
                        no-title
                        scrollable
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.correoLdap"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="crearFuncionario()"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          outlined
          class="mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <div>
        <v-btn
          class="mr-2"
          icon
          :to="{ name: 'funcionario', params: { id: item.id } }"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    funcionarios: Array,
    cargando: Boolean,
  },
  data() {
    return {
      fechaAntiguedadUniversidad: "",
      fechaAntiguedad: "",
      fechaNacimiento: "",
      antiguedadPuesto: "",
      menu1: false,
      menu2: false,
      menu3: false,
      dialog: false,
      search: "",
      height: 400,
      fixed: true,
      selected: [],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        rut: "",
        unidad: "",
        planta: "",
        grado: "",
        correoLdap: "",
        antiguedadPuesto: "",
        antiguedadUniversidad: "",
        fechaNacimiento: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Funcionario"
        : "Editar Funcionario";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    editedIndex() {},
  },
  methods: {
    initialize() {
      this.funcionariosLocal = [];
      this.sendData();
    },

    sendData() {
      this.$emit("send-data", this.funcionariosLocal);
    },

    editItem(item) {
      this.cargando = true;
      this.funcionarios = [];
      //TODO: Cambiar busqueda por algo unico
      this.editedIndex = this.funcionariosLocal.findIndex(
        (itemBuscado) => itemBuscado.nombre === item.nombre
      );
      this.editedItem = this.funcionariosLocal[this.editedIndex];
      this.dialog = true;
    },


    //TO-DO: quitar el seteo en 1
    crearFuncionario() {
      this.editItem.fechaNacimiento = this.formatDate(this.editItem.fechaNacimiento);
      /* this.editItem.antiguedadPuesto = this.formatDate(this.editItem.antiguedadPuesto); */
      this.editItem.antiguedadUniversidad = this.formatDate(this.editItem.antiguedadUniversidad);
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/identificacion/createIdentificacion?idPuestoTrabajo=" +
            1,
          this.editedItem,
          this.auth
        )
        .then((response) => {
          this.dialog = false;
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>