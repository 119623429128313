<template>
  <v-container>
    <v-row justify="center">
      <Modal
        @update-data="createCapacitacion"
        :title="'Crear Nueva Capacitación Externa'"
        :object="capacitacionVaciaE"
        :namesInputs="namesInputs"
        :nameButton="'Agregar capacitación externa'"
        :icon="'mdi-plus'"
        :color="'green'"
      />
      <Modal
        @update-data="createCapacitacion"
        :title="'Crear Nueva Capacitación Interna'"
        :object="capacitacionVaciaI"
        :namesInputs="namesInputs"
        :nameButton="'Agregar capacitación interna'"
        :icon="'mdi-plus'"
        :color="'green'"
      />
      <v-col cols="10">
        <v-simple-table
          class="mt-5"
          v-for="(capacitacion, index) in capacitaciones"
          :key="index"
          :dense="true"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left border textColor">
                  {{ capacitacion.nombreActividad }}
                </th>
                <th class="text-right no-border">
                  <v-chip
                    class="ma-2"
                    color="green"
                    v-if="capacitacion.verificado"
                    label
                    text-color="white"
                  >
                    <v-icon left> mdi-label </v-icon>
                    Verificado
                  </v-chip>
                  <ModalConfirm
                    v-if="!capacitacion.verificado"
                    @update-data="updateVerificacion"
                    :title="'¿Verificación de Capacitación?'"
                    :content="'Si estás conforme con la información subida por , presiona verificar'"
                    :nameButton="'verificar'"
                    :icon="'mdi-check'"
                    :color="'green'"
                    :id="capacitacion.id"
                  />
                  <Modal
                    @update-data="updateCapacitacion"
                    :title="'Editar datos de Capacitación'"
                    :object="capacitacion"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                  <ModalConfirm
                    @update-data="deleteCapacitacion"
                    :title="'Eliminar Capacitación'"
                    :content="'¿Estás seguro de eliminar esta capacitación?'"
                    :nameButton="'eliminar'"
                    :icon="'mdi-delete'"
                    :color="'red'"
                    :id="capacitacion.id"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Tipo de la Actividad</td>
                <td class="border-right">{{ capacitacion.tipoActividad }}</td>
              </tr>
              <tr>
                <td class="border">Horas de duracion</td>
                <td class="border-right">{{ capacitacion.horas }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de término</td>
                <td class="border-right">{{ capacitacion.fecha }}</td>
              </tr>
              <tr>
                <td class="border">Certificado</td>
                <td class="border-right">{{ capacitacion.certificado }}</td>
              </tr>
              <tr>
                <td class="border">¿Es capación interna?</td>
                <td class="border-right">{{ verificarCapacitacion(capacitacion.interno) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
import ModalConfirm from "./ModalConfirmacion";

export default {
  components: {
    Modal,
    ModalConfirm,
  },
  data() {
    return {
      dense: false,
      fixedHeader: false,
      height: "auto",
      //agregar los objetos vacios
      capacitacionVaciaI: {
        tipoActividad: "",
        nombreActividad: "",
        horas: "",
        fecha: "",
        certificado: "",
        interno: true,
        verificado: false,
      },
      capacitacionVaciaE: {
        tipoActividad: "",
        nombreActividad: "",
        horas: "",
        fecha: "",
        certificado: "",
        interno: false,
        verificado: false,
      },
      capacitaciones: [],
      namesInputs: [
        { title: "Tipo de Actividad", key: "tipoActividad" },
        { title: "Nombre de la Actividad", key: "nombreActividad" },
        { title: "Horas", key: "horas" },
        { title: "Fecha", key: "fecha" },
        { title: "certificado", key: "certificado" },
      ],
    };
  },

  created() {
    this.getCapacitaciones();
  },
  methods: {
    getCapacitaciones() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/capacitacion/obtenerListadoPorIdentificacion?idIdentificacion=" +
            this.$route.params.id,
          this.auth
        )
        .then((response) => {
          this.capacitaciones = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    verificarCapacitacion(valor){
      if (valor === false){
        return "No"
      }else {return "Sí"}
    },
    updateCapacitacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las capacitaciones y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL + "/capacitacion/update",
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha actualizado una experiencia");
          this.getCapacitaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },
    createCapacitacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las capacitaciones y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/capacitacion/createCapacitacion?idIdentificacion=" +
            this.$route.params.id,
          event,
          this.auth
        )
        .then((response) => {
          alert("Ha añadido una capacitacion");
          this.getCapacitaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },
    updateVerificacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .patch(
          process.env.VUE_APP_BASE_URL +
            "/capacitacion/cambiaEstadoVerificacion?id=" +
            event +
            "&verificado=true",
          this.auth
        )
        .then((response) => {
          alert("Se ha verificado una capacitación");
          this.getCapacitaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteCapacitacion(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL +
            "/capacitacion/eliminarCapacitacion?id=" +
            event,
          this.auth
        )
        .then((response) => {
          alert("Se ha eliminado una capacitación");
          this.getCapacitaciones();
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}
.textColor {
  background: orange;
  color: white !important;
}
</style>