<template>
  <div>
    <h1 class="c-orange">Buscador</h1>
    <v-spacer />
    <!-- <v-row>
      <v-col cols="5">
        <v-autocomplete
          outlined
          dense
          hint="Ingrese el nivel de estudios por el cual desea buscar"
          persistent-hint
          :items="itemsNivel"
          item-text="nombre"
          label="Nivel Estudios"
        ></v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          outlined
          dense
          hint="Ingrese el curso realizado a buscar"
          persistent-hint
          :items="itemsCursos"
          item-text="nombre"
          label="Curso"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-row>
          <v-btn color="primary" block dark>Buscar</v-btn>
        </v-row>
      </v-col>
    </v-row> -->
    <v-spacer />
    <TableFuncionarios
      @send-data="sendInfo"
      v-bind:searchMode="true"
      v-bind:headers="headers"
      v-bind:funcionarios="funcionarios"
      v-bind:adminMode="false"
      v-bind:favoritosMode="false"
      v-bind:cargando="cargando"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/TableCrud.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios,
  },
  data() {
    return {
      cargando: true,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      loading: "cargando",
      itemType: "Proceso",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Rut", value: "rut" },
        { text: "Unidad", value: "unidad" },
        { text: "Planta", value: "planta" },
        { text: "Grado", value: "grado" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      itemsNivel: [
        {
          id: 1,
          nombre: "Media",
        },
        {
          id: 2,
          nombre: "Técnico",
        },
        {
          id: 3,
          nombre: "Universitaria",
        },
      ],
      itemsCursos: [
        {
          id: 1,
          nombre: "curso 1",
        },
        {
          id: 2,
          nombre: "curso 2",
        },
        {
          id: 3,
          nombre: "curso 3",
        },
      ],
      funcionarios: [],
    };
  },
  created() {
    this.getAllIdentificaciones();
  },
  methods: {
    getAllIdentificaciones() {
      this.cargando = true;
      this.funcionarios = [];
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/identificacion/getNotFavoritos?idProceso=" +
            this.$route.params.idProceso,
          this.auth
        )
        .then((response) => {
          this.funcionarios = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },
    sendInfo(event) {
      this.getAllIdentificaciones();
    },
  },
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>