<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10">
        <v-simple-table class="mt-2" :dense="true" :fixed-header="fixedHeader" :height="height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left border textColor">Identificación</th>
                <th class="text-right no-border">
                  <v-btn class="mr-2" color="orange" icon @click="download()">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <Modal
                    @update-data="updateCv"
                    :title="'Editar datos de curriculum'"
                    :object="identificacion"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Nombre Completo</td>
                <td class="border-right">{{ identificacion.nombre }}</td>
              </tr>
              <tr>
                <td class="border">Rut</td>
                <td class="border-right">{{ identificacion.rut }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de nacimiento</td>
                <td class="border-right">{{ identificacion.fechaNacimiento }}</td>
              </tr>
              <tr>
                <td class="border">Puesto</td>
                <td class="border-right">{{ identificacion.puestoTrabajo['nombre'] }}</td>
              </tr>
              <tr>
                <td class="border">Planta</td>
                <td class="border-right">{{ identificacion.planta }}</td>
              </tr>
              <tr>
                <td class="border">Grado</td>
                <td class="border-right">{{ identificacion.grado }}</td>
              </tr>
              <tr>
                <td class="border">Unidad</td>
                <td class="border-right">{{ identificacion.unidad }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de ingreso al puesto</td>
                <td class="border-right">{{ identificacion.antiguedadPuesto }}</td>
              </tr>
              <tr>
                <td class="border">Fecha de ingreso a la Universidad</td>
                <td class="border-right">{{ identificacion.antiguedadUniversidad }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import tableParser from "@/utils/CvTableParser";
export default {
  components: {
    Modal
  },
  data() {
    return {
      fixedHeader: false,
      height: "auto",
      identificacion: { puestoTrabajo: { nombre: "" } },

      namesInputs: [
        { title: "Nombre Funcionario", key: "nombre" },
        { title: "Rut", key: "rut" },
        { title: "Planta", key: "planta" },
        { title: "Grado", key: "grado" },
        { title: "Unidad", key: "unidad" },
        { title: "Fecha de ingreso al puesto", key: "antiguedadPuesto" },
        {
          title: "Fecha de ingreso a la Universidad",
          key: "antiguedadUniversidad"
        },
        { title: "Fecha de nacimiento", key: "fechaNacimiento" }
      ]
    };
  },

  created() {
    this.getIdentificacion();
  },

  methods: {
    getIdentificacion() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/identificacion/getById?id=" +
            this.$route.params.id,
          this.auth
        )
        .then(response => {
          this.identificacion = response.data;
        })
        .catch(error => {
          alert(error);
        });
    },

    updateCv(event) {
      console.log("acá estamos ene l padre");
      console.log(event);
      alert("Ha modificado el cv");
      this.identificacion = event;
    },
    download() {
      //documentación: https://github.com/simonbengtsson/jsPDF-AutoTable
      const doc = new jsPDF();
      tableParser(doc, "Identificación", this.identificacion, this.namesInputs);
      tableParser(doc, "Formación Academica", {}, []);
      tableParser(doc, "Experiencia Laboral", {}, []);
      tableParser(doc, "Capacitaciones", {}, []);
      doc.save("table.pdf");
    }
  }
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}

.textColor {
  background: orange;
  color: white !important;
}
</style>