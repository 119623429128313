<template>
  <v-content class="content">
    <v-row>
      <v-col v-for="(n, index) in items" :key="index" cols="6" :sm="6" :md="4" :lg="6">
        <v-card flat tile class="d-flex">
          <div class="contenedor">
            <v-img :src="n.src" aspect-ratio="1" class="image">
              <template v-slot:placeholder>
                <v-row justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card class="image fondo">
              <p>{{n.name}}</p>
            </v-card>

            <div class="middle">
              <v-col cols="12">
                <v-row justify="center">
                  <v-btn :to="{path: n.path}" dark color="#eb7704">{{n.name}}</v-btn>
                </v-row>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import img1 from "../assets/u.png";
import img2 from "../assets/folder.png";

export default {
  data() {
    return {
      items: [
        {
          name: "Ingrese a sus procesos",
          src: img1,
          path: "/procesosJefatura"
        },
        {
          name: "Instructivos y procedimiento",
          src: img2,
          path: "/instructivos"
        }
      ]
    };
  }
};
</script>
<style scoped>
.contenedor:hover .middle {
  opacity: 1;
}

.image {
  background-color: white !important;
  display: block;
  width: 70%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.middle {
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.contenedor {
  width: 100%;
  padding: 0;
}

.fondo {
  background-color: #ff9800 !important;
  margin-top: 5px;
  text-align: center;
  color: white;
  justify-content: center;
  align-content: center;
}

.contenedor:hover .image {
  opacity: 0.3;
}
.content {
  margin-top: 15vh;
  margin-bottom: 15vh;
}
</style>