<template>
  <v-content>
    <v-row>
      <v-tabs fixed-tabs background-color="grey" dark>
        <v-tab :to="{name: 'cv'}">currículum</v-tab>
        <v-tab :to="{name: 'capacitacion'}">Capacitación</v-tab>
        <v-tab :to="{name: '#'}">Puestos de interés</v-tab>
      </v-tabs>
    </v-row>
  </v-content>
</template>

<script>
export default {
  name: "TabPerfil"
};
</script>


<style scoped>
.nav {
  margin-bottom: 1% !important;
}
.test {
  height: 300px !important;
  overflow-y: auto;
}
</style>