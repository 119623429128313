<template>
  <v-content>
    <v-row>
      <v-col cols="12">
        <Navbar/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container class="pull-down">
          <!-- Todo lo demás se muestra dentro de esto -->
          <router-view></router-view>
        </v-container>
      </v-col>
    </v-row>
    <Footer/>
  </v-content>
</template>

<script>
import Footer from './Footer'
import Navbar from './Navbar'
export default {
  name: "Layout",
  components: {
    Footer,
    Navbar
  },
  beforeCreate: () => {
    console.log('layout existe');
  }
};
</script>

<style scoped>
.nav {
  margin-bottom: 1% !important;
}
.pull-down{
  margin-top: 3%;
  margin-bottom: 3%;
  background: white;
}
</style>
