<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" small outlined :color="color" v-on="on">
        <v-icon left>{{icon}}</v-icon>
        {{nameButton}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row v-for="(nameInput,index) in namesInputs" :key="index">
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="objectChild[nameInput.key]"
              outlined
              dense
              :label="nameInput.title"
              persistent-hint
              :hint="'Ingrese ' + nameInput.title"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="closeDialog()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    object: Object,
    namesInputs: Array,
    nameButton: String,
    icon: String,
    color: String
  },
  data() {
    return {
      dialog: false,
      objectChild: {},
      menu1: false,
      menu2: false,
      fechaInicio: "",
      fechaTermino: "",
      folio: "",
      puestoSelected: {},
      nombresVmodel: []
    };
  },

  watch: {
    dialog: function() {
      console.log("mostrando el object");
      console.log(this.object);
      console.log(this.namesInputs);
      console.log(Object.keys(this.object));
      this.nombresVmodel = Object.keys(this.object);
      this.objectChild = JSON.parse(JSON.stringify(this.object));
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("update-data", this.objectChild);
    }
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 12px 24px 0 !important;
}
</style>