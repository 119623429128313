<template>
  <TableFuncionarios
    v-bind:headers="headers"
    v-bind:funcionarios="items"
    v-bind:cargando="cargando"
  />
</template>
<script>
import TableFuncionarios from "@/components/TableFuncionarioAdmin.vue";
export default {
  name: "BuscarFuncionarioAdmin",
  components: { TableFuncionarios },
  data() {
    return {
      cargando: true,
      valid: false,
      nameRules: [(v) => !!v || "Este campo es requerido"],
      items: [],
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Rut", value: "rut" },
        { text: "Unidad", value: "unidad" },
        { text: "Planta", value: "planta" },
        { text: "Grado", value: "grado" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      funcionarios: [],
    };
  },

  created() {
    this.getAllIdentificaciones();
  },
  methods: {
    getAllIdentificaciones() {
      this.cargando = true;
      this.funcionarios = [];
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/identificacion/getAll", this.auth)
        .then((response) => {
          this.items = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },
  },
};
</script>

<style scoped>
</style>