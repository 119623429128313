<template>
  <v-app>
    <Layout />
  </v-app>
</template>

<script>
import Layout from "./components/layout component/Layout";
export default {
  name: "App",

  components: {
    Layout
  },

  data() {
    return {
      //
    };
  }
};
</script>
