<template>
  <v-container>
    <v-row justify="center">
      <!-- <Modal
        @update-data="createCv"
        :title="'Crear Nueva experiencia'"
        :object="experienciaVacia"
        :namesInputs="namesInputs"
        :nameButton="'Agregar experiencia'"
        :icon="'mdi-plus'"
        :color="'green'"
      /> -->
      <v-col cols="10">
        <v-simple-table
          class="mt-5"
          v-for="(experiencia,index) in experiencias"
          :key="index"
          :dense="true"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left border textColor">{{experiencia.name}}</th>
                <th class="text-right no-border">
                  <Modal
                    @update-data="updateCv"
                    :title="'Editar datos de Experiencia'"
                    :object="experiencia"
                    :namesInputs="namesInputs"
                    :nameButton="'Editar'"
                    :icon="'mdi-pencil'"
                    :color="'orange'"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">Cargo</td>
                <td class="border-right">{{ experiencia.cargo }}</td>
              </tr>
              <tr>
                <td class="border">Periodo</td>
                <td class="border-right">{{ experiencia.periodo }}</td>
              </tr>
              <tr>
                <td class="border">Institución</td>
                <td class="border-right">{{ experiencia.institucion }}</td>
              </tr>
              <tr>
                <td class="border">Funciones</td>
                <td class="border-right">{{ experiencia.funciones }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "./Modal";

export default {
  components: {
    Modal
  },
  data() {
    return {
      dense: false,
      fixedHeader: false,
      height: "auto",
      experienciaVacia: {
        id: "20",
        name: "",
        cargo: "",
        periodo: "",
        institucion: "",
        funciones: ""
      },
      experiencias: [
        {
          id: 1,
          name: "Experiencia 1",
          cargo: "Analista de sistemas en algo",
          periodo: "2019",
          institucion: "Universidad de Chile",
          funciones:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
          id: 2,
          name: "Experiencia 2",
          cargo: "Ingeriero en procesos",
          periodo: "2009",
          institucion: "Universidad de Chile",
          funciones:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
          id: 3,
          name: "Experiencia 3",
          cargo: "Profesor de Lenguaje",
          periodo: "2019",
          institucion: "Universidad de Santiago",
          funciones: "Ejemplo"
        }
      ],
      namesInputs: [
        "id",
        "Nombre",
        "Cargo",
        "Periodo",
        "Institución",
        "Funciones"
      ]
    };
  },
  methods: {
    updateCv(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      alert("Ha modificado la experiencia  " + event.name);
    },
    createCv(event) {
      //Acá en teoria llega el item modificado y se updatea en el backend, luego hay que volver a llamar a todas las experiencias y renderizarlas denuevo
      console.log("acá estamos en el padre");
      console.log(event);
      alert("Ha creado la experiencia  " + event.name);
      this.experiencias.push(event);
    }
  }
};
</script>


<style scoped>
.border {
  border: 1px solid orange !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  color: orange !important;
  border-radius: 5px;
}

.no-border {
  border: none !important;
}

.border-right {
  border: 1px solid orange !important;
  border-right: 0 !important;
  border-radius: 5px;
  border-bottom: 0 !important;
}
.textColor {
  background: orange;
  color: white !important;
}
</style>