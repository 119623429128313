<template>
  <v-container class="fill-height" fluid>
    <v-app-bar fixed color="orange" class="nav mx-btn">
      <img
        class="img-fix"
        alt="Logo Campus Seguro"
        :src="img1"
        height="65"
        style="margin-right: 30px"
    /></v-app-bar>
    <img :src="img" class="parallax" />

    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-toolbar color="orange" dark flat align-center>
            <v-toolbar-title>Acceso a Movilidad Interna</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form id="formVrae" v-model="valid"
            >
            <!-- :lazy-validation="lazy" Va arriba -->
              <v-select
                return-object
                prepend-icon="mdi-library"
                outlined
                dense
                item-text="name"
                :items="opcionesTipo"
                persistent-hint
                label="Tipo de usuario"
                v-model="tipo"
              ></v-select>
              <v-text-field
                dense
                outlined
                label="Nombre de usuario (correo Usach sin extensión)"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="user"
              ></v-text-field>
              <v-select
                return-object
                prepend-icon="mdi-mail"
                outlined
                dense
                :items="opcionesDominio"
                persistent-hint
                label="Dominio de correo"
                v-model="dominio"
              ></v-select>

              <v-text-field
                outlined
                dense
                id="password"
                label="Contraseña de correo"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="pass"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              :disabled="!valid"
              :loading="loading"
              @click="login()"
              >Ingresar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import axios from "axios";
import jwtDecode from "jwt-decode";
import img1 from "../assets/LOGO USACH.png";
import img from "../assets/USACH_2.jpg";
import sha1 from 'js-sha1';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      user: "",
      pass: "",
      loading: false,
      img: img,
      img1: img1,
      tipo: "",
      opcionesTipo: [
        {
          name: "Funcionario",
          value: 1
        },
        {
          name: "Jefatura",
          value: 2
        },
        {
          name: "Administrador",
          value: 3
        }
      ],
      opcionesDominio: ["@segic.usach.cl", "@usach.cl"],
      dominio: "",
      pass: "",
    };
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        const user = {
          usuario: this.user,
          password: sha1(this.pass),
          dominio: this.dominio,
          tipo: this.tipo.value
        };
        console.log(user);

        const response = await this.axios.post(`${process.env.VUE_APP_BASE_URL}/login`, user);

        // console.log(response);
        // var Decode = jwtDecode(response.data);
        // console.log(Decode);
        // const role = Decode.authorities;
        // console.log(role);
        //
        // const generarSesion = async (decode, token) => {
        //   await this.$session.start();
        //   console.log(token);
        //   await this.$session.set("jwt", "Bearer " + token);
        //   console.log(this.$session.get("jwt"));
        //   console.log(decode.authorities);
        //   await this.$session.set("typeUser", decode.authorities);
        //   console.log(this.$session.typeUser);
        // }
        // await generarSesion(Decode, response.data);
        // console.log(this.$session.jwt);
        //
        //
        // console.log(this.$session.typeUser);
        //
        // console.log('termine de crear sesion');
        this.$session.start();
        this.$session.set("jwt", "Bearer " + response.data);

        var Decode = jwtDecode(response.data);

        console.log(this.$session.get("jwt"));
        this.$session.set("typeUser", Decode.authorities[0]);
        // this.$session.set("groupId", Decode.groupId);
        // this.$session.set("groupName", Decode.groupName);

        switch (this.$session.getAll().typeUser) {
          case "admin":
            console.log('shau nos vimo');
            this.$router.push("/menuadmin");
          break;
          case "jefe":
            this.$router.push("/menu");
          break;
          case "funcionario":
            this.$router.push("/menufuncionario");
          break;
          default:
            console.log('chau');
        }

        // this.axios
        //   .post(process.env.VUE_APP_BASE_URL + "/authenticate", user)
        //   .then((response) => {


        //console.log('haciendo la mutación')

        /*
        console.log(Decode.sub);
        this.$session.set("id", Decode.identificador);
        this.$session.set("email", Decode.email); */
        this.loading = false;

      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      //console.log(this.mail)
    },
  },
};
</script>

<style scoped>
.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}
</style>
