import Vue from 'vue'
import VueRouter from 'vue-router'
import Hello from '../components/HelloWorld.vue'
import ListadoFavoritos from '../views/ListadoFavoritos.vue'
import ListadoFavoritosJefatura from '../views/ListadoFavoritosJefatura.vue'
import BuscadorFuncionarios from '../views/BusquedaFuncionarios.vue'
import BuscarFuncionarioAdmin from '../views/BuscarFuncionarioAdmin.vue'
import ProcesosAdmin from '../views/ProcesosAdmin.vue'
import ProcesosJefatura from '../views/ProcesosJefatura.vue'
import UploadInstructive from '../views/UploadInstructive.vue'
import Mensaje from '../components/MensajeContacto.vue'
import LayoutFuncionario from '../components/layout funcionario/LayoutFuncionario'
import LayoutPerfil from '../components/layout funcionario/LayoutPerfil'
import Curriculum from '../components/funcionario/Curriculum'
import Formacion from '../components/funcionario/Formacion'
import Experiencia from '../components/funcionario/Experiencia'
import Capacitacion from '../components/funcionario/Capacitacion'
import Interes from '../components/funcionario/PuestoInteres'

import MenuJF from '../components/MenuJF'
import MenuFuncionario from '../components/MenuFuncionario'
import MenuAdmin from '../components/MenuAdmin'
import Cv from '../components/perfil/Curriculum'
import Login from '../components/Login'
import Instructivos from '../components/Instructivos'

import CurriculumAdmin from '../components/funcionarioAdmin/Curriculum'
import FormacionAdmin from '../components/funcionarioAdmin/Formacion'
import ExperienciaAdmin from '../components/funcionarioAdmin/Experiencia'
import LayoutFuncionarioAdmin from '../components/layout funcionario/LayoutFuncionarioAdmin'

import Cargar from '../views/Cargar.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/inicio' },
  {
    path: '/inicio',
    name: 'Hello',
    component: Hello
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/procesos/:idProceso/listadoFavoritos',
    name: 'ListadoFavoritos',
    component: ListadoFavoritos,
    beforeEnter: (to, from, next) => {
      if (router.app.$session.getAll().typeUser == 'jefe'
      || router.app.$session.getAll().typeUser == 'admin'
      || router.app.$session.getAll().typeUser == 'funcionario') next()
      else next({ name: 'login' })
    }
  },
  {
    path: '/procesos/:idProceso/listadoFavoritosJefatura',
    name: 'ListadoFavoritosJefatura',
    component: ListadoFavoritosJefatura,
    beforeEnter: (to, from, next) => {
      if (router.app.$session.getAll().typeUser == 'jefe'
      || router.app.$session.getAll().typeUser == 'admin'
      || router.app.$session.getAll().typeUser == 'funcionario') next()
      else next({ name: 'login' })
    }
  },
  {
    path: '/procesos/:idProceso/buscadorFuncionarios',
    name: 'BuscadorFuncionarios',
    component: BuscadorFuncionarios,
    beforeEnter: (to, from, next) => {
      if (router.app.$session.getAll().typeUser == 'jefe'
      || router.app.$session.getAll().typeUser == 'admin'
      || router.app.$session.getAll().typeUser == 'funcionario') next()
      else next({ name: 'login' })
    }
  },
  {
    path: '/buscarFuncionario',
    name: 'BuscarFuncionarioAdmin',
    component: BuscarFuncionarioAdmin
  },
  {
    path: '/procesos',
    name: 'ProcesosAdmin',
    component: ProcesosAdmin
  },
  {
    path: '/procesosJefatura',
    name: 'ProcesosJefatura',
    component: ProcesosJefatura
  },
  {
    path: '/subirInstructivo',
    name: 'UploadInstructive',
    component: UploadInstructive
  },
  {
    path: '/mensaje',
    name: 'Mensaje',
    component: Mensaje
  },
  {
    path: '/funcionario/:id',
    name: 'funcionario',
    component: LayoutFuncionario,
    beforeEnter: (to, from, next) => {
      if (router.app.$session.getAll().typeUser == 'jefe'
      || router.app.$session.getAll().typeUser == 'admin'
      || router.app.$session.getAll().typeUser == 'funcionario') next()
      else next({ name: 'login' })
    },
    redirect: '/funcionario/:id/curriculum/',
    children: [
      {
      path: '/funcionario/:id/curriculum',
      name: 'curriculum',
      component: Curriculum,
    },
    {
      path: '/funcionario/:id/formacion',
      name: 'formacion',
      component: Formacion,
    },
    {
      path: '/funcionario/:id/capacitacion',
      name: 'capacitacion',
      component: Capacitacion,
    },
    {
      path: '/funcionario/:id/interes',
      name: 'interes',
      component: Interes,
    },
    {
      path: '/funcionario/:id/experiencia',
      name: 'experiencia',
      component: Experiencia,
    }]
  },
  {
    path: '/funcionarioAdmin/:id',
    name: 'funcionarioAdmin',
    component: LayoutFuncionarioAdmin,
    beforeEnter: (to, from, next) => {
      if (router.app.$session.getAll().typeUser == 'jefe'
      || router.app.$session.getAll().typeUser == 'admin'
      || router.app.$session.getAll().typeUser == 'funcionario') next()
      else next({ name: 'login' })
    },
    redirect: '/funcionarioAdmin/:id/curriculumAdmin/',
    children: [
      {
        path: '/funcionarioAdmin/:id/curriculumAdmin',
        name: 'curriculumAdmin',
        component: CurriculumAdmin,
      },
      {
        path: '/funcionarioAdmin/:id/formacion',
        name: 'formacionAdmin',
        component: FormacionAdmin,
      },
      {
        path: '/funcionarioAdmin/:id/experiencia',
        name: 'experienciaAdmin',
        component: ExperienciaAdmin,
      }]
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuJF,
  },
  {
    path: '/menufuncionario',
    name: 'menuFuncionario',
    component: MenuFuncionario,
  },
  {
    path: '/menuadmin',
    name: 'menuAdmin',
    component: MenuAdmin,
  },
  {
    path: '/instructivos',
    name: 'instructivos',
    component: Instructivos,
  },
  {
    path: '/cargar',
    name: 'cargar',
    component: Cargar,
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
