<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" small outlined :color="color" v-on="on">
        <v-icon left>{{ icon }}</v-icon>
        {{ nameButton }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        {{ content }}
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" small outlined color="grey" @click="dialog = false">
          cancelar
        </v-btn>

        <v-btn class="ma-2" small outlined :color="color" @click="closeDialog()">
          <v-icon left>{{ icon }}</v-icon>
          {{ nameButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmacion",
  props: {
    title: String,
    content: String,
    nameButton: String,
    icon: String,
    color: String,
    id: Number,
  },
  data() {
    return {
      dialog: false,
      objectChild: {},
      menu1: false,
      menu2: false,
      fechaInicio: "",
      fechaTermino: "",
      folio: "",
      puestoSelected: {},
      nombresVmodel: [],
    };
  },

  
  

  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("update-data", this.id);
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 12px 24px 0 !important;
}
</style>