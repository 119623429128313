<template>
  <div>
    <v-form v-model="valid" :lazy-validation="lazy">
      <h2 class="c-orange">Seleccione el centro de costos para administrar procesos</h2>
      <v-row>
        <v-col cols="10">
          <v-autocomplete
            v-model="ccSelected"
            :rules="CCRules"
            outlined
            dense
            :items="items"
            item-text="ccNombre"
            item-value="ccValor"
            label="Centro de costos"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-btn block :disabled="!valid" @click.prevent="searchForm" color="primary">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="buscado">
      <v-spacer />
      <h1 class="c-orange">PROCESOS VIGENTES</h1>
      <v-spacer />
      <TableProcesses
        @send-data="sendInfo"
        v-bind:activeProcesses="true"
        :admin="true"
        :itemType="itemType"
        :ccSelected="ccSelected"
        v-bind:headers="headers"
        v-bind:tableRows="procesosActivos"
      />
    </div>

    <div v-if="existenInactivos">
      <v-spacer />
      <h1 class="c-orange">HISTORIAL</h1>
      <v-spacer />
      <TableProcesses
        @send-data="sendInfo"
        v-bind:activeProcesses="false"
        :admin="true"
        :itemType="itemType"
        :ccSelected="ccSelected"
        v-bind:headers="headers"
        v-bind:tableRows="procesosInactivos"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TableProcesses from "@/components/TableProcesses.vue";
export default {
  name: "ProcesosAdmin",
  components: {
    TableProcesses
  },
  data() {
    return {
      buscado: false,
      existenInactivos: false,
      lazy: false,
      valid: false,
      ccSelected: "",
      loading: "cargando",
      itemType: "Proceso",
      CCRules: [v => !!v || "Este campo es requerido"],
      headers: [
        { text: "Folio", value: "folio", width: "10%" },
        { text: "Puesto", value: "puestoTrabajo.nombre", width: "30%" },
        { text: "Planta", value: "puestoTrabajo.planta", width: "10%" },
        { text: "Grado", value: "grado", width: "10%" },
        { text: "Fecha Inicio", value: "fechaInicio", width: "15%" },
        { text: "Fecha Termino", value: "fechaTermino", width: "15%" },
        { text: "Acciones", value: "acciones", sortable: false, width: "10%" }
      ],
      items: [],
      procesosActivos: [],
      procesosInactivos: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },
  watch: {
    ccSelected: function() {
      this.buscado = false;
      this.existenInactivos = false;
      this.procesosActivos = [];
      this.procesosInactivos = [];
    }
  },

  created() {
    this.getAllCC();
  },

  methods: {
    sendInfo(event) {
      console.log(event);
      this.getAllProcesosCCInactivos();
      this.getAllProcesosCCActivos();
    },
    getAllCC() {
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/centrocosto/getAllCC", this.auth)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          alert(error);
        });
    },

    getAllProcesosCCActivos() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/proceso/obtenerProcesosCCyEstado?ccValor=" +
            this.ccSelected +
            "&estado=true",
          this.auth
        )
        .then(response => {
          this.procesosActivos = response.data;
        })
        .catch(error => {
          alert(error);
        });
    },
    getAllProcesosCCInactivos() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/proceso/obtenerProcesosCCyEstado?ccValor=" +
            this.ccSelected +
            "&estado=false",
          this.auth
        )
        .then(response => {
          this.procesosInactivos = response.data;
          if (this.procesosInactivos.length > 0) this.existenInactivos = true;
          else this.existenInactivos = false;
        })
        .catch(error => {
          alert(error);
        });
    },
    searchForm() {
      this.buscado = true;
      this.getAllProcesosCCInactivos();
      this.getAllProcesosCCActivos();
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>