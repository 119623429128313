<template>
  <v-content>
    <v-row>
      <v-col cols="12">
        <TabAdmin />
      </v-col>
    </v-row>

    <v-row class="test">
      <router-view></router-view>
    </v-row>
  </v-content>
</template>

<script>
import TabAdmin from "./TabAdmin";
export default {
  name: "LayoutFuncionarioAdmin",
  components: {
    TabAdmin
  }
};
</script>


<style scoped>
.nav {
  margin-bottom: 1% !important;
}

.test {
  max-height: 70vh !important;
  overflow-y: auto;
}
</style>