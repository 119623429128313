<template>
  <v-content class="content">
    <v-row>
      <v-col
        v-for="(n, index) in items"
        :key="index"
        cols="6"
        :sm="12"
        :md="3"
        :lg="3"
      >
        <v-card flat tile class="d-flex">
          <div class="contenedor">
            <v-img :src="n.src" aspect-ratio="1" class="image">
              <template v-slot:placeholder>
                <v-row justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card class="image fondo">
              <p>{{ n.name }}</p>
            </v-card>

            <div class="middle">
              <v-col cols="12">
                <v-row justify="center">
                  <v-btn :to="{ path: n.path }" dark color="#eb7704">{{
                    n.name
                  }}</v-btn>
                </v-row>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import img1 from "../assets/u.png";
import img2 from "../assets/cv.png";
import img3 from "../assets/comment.png";
import img4 from "../assets/document.png";
import img5 from "../assets/folder.png";

export default {
  data() {
    return {
      items: [
        {
          name: "Administrar centros de costos",
          src: img1,
          path: "/procesos",
        },
        {
          name: "Cargar información",
          src: img4,
          path: "/cargar",
        },
        {
          name: "Editar mensaje de contacto",
          src: img3,
          path: "/mensaje",
        },
        {
          name: "Gestionar instructivos",
          src: img5,
          path: "/subirInstructivo",
        },
        {
          name: "Administrar funcionarios",
          src: img2,
          path: "/buscarFuncionario",
        },
      ],
    };
  },
};
</script>
<style scoped>
.contenedor:hover .middle {
  opacity: 1;
}

.image {
  background-color: white !important;
  display: block;
  width: 70%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.middle {
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.contenedor {
  width: 100%;
  padding: 0;
}

.fondo {
  background-color: #ff9800 !important;
  margin-top: 5px;
  text-align: center;
  color: white;
  justify-content: center;
  align-content: center;
}

.contenedor:hover .image {
  opacity: 0.3;
}
.content {
  margin-top: 15vh;
  margin-bottom: 15vh;
}
</style>